import React from "react"
import {  StaticQuery, graphql } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import parse from 'html-react-parser'
import Navigation from "../components/navigation"
import SocialShare from "../components/social_share"
import CTAThankYou from "../components/utility/cta_thank_you"
import Buttons from "../components/common/button"
import Frame from "../components/common/frame"

function ResourceEbookMain({ pageContext }) {
  const { resource } = pageContext

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentResourceEbookMain {
            SuperOps {
              pages(where: { title: "Resources — Ebook" }) {
                title
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
              }
            }
            site {
              siteMetadata {
                url
                twitterHandle
              }
            }
          }
        `}
        render={data => (
          <div className="ebook-single-main">
            {data.SuperOps.pages.map(page => {
              const { title, navigationBlock } = page

              const twitterHandle = data.site.siteMetadata.twitterHandle
              const url = data.site.siteMetadata.url
              const shareTags = []

              return (
                <>
                  <Frame
                    seo={resource.seo[0]}
                  >
                    <header>
                      <Navigation links={navigationBlock} page={title} />
                    </header>

                    <section className="es-banner-wrap">
                      <Container>
                        <center>
                          <div className="share">
                            <SocialShare
                              socialConfig={{
                                twitterHandle,
                                config: {
                                  url: `${url}resources/books/${resource.slug}`,
                                  title: resource.title,
                                },
                              }}
                              tags={shareTags}  
                              direction="vrt"
                            />
                          </div>
                          <div
                            className="outer"
                            style={{ background: resource.bgColor }}
                          >
                            <Row>
                              <Col md={5}>
                                <div className="cover">
                                  <img
                                    src={resource.image.url}
                                    height="100"
                                    width="100"
                                    alt="img"
                                  />
                                </div>
                              </Col>
                              <Col md={7}>
                                <div className="category">
                                  <p>
                                    {" "}
                                    {resource.category} {resource.seo.title}{" "}
                                  </p>
                                  <div className="line" />
                                </div>
                                <div className="title">
                                  <h1> {resource.title}</h1>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="inner">
                            <Row>
                              <Col lg={5} md={0}></Col>
                              <Col lg={7} md={12}>
                                <div className="excerpt">
                                  <p>
                                    {parse(resource.excerpt.html)}
                                  </p>
                                </div>
                                <Buttons
                                  theme="primary"
                                  link={`/resources/books/${resource.slug}/${resource.chapterSlug}`}
                                  text="READ BOOK"
                                />
                              </Col>
                            </Row>
                          </div>
                        </center> 
                        
                      </Container>
                    </section>
                    <CTAThankYou />
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default ResourceEbookMain
